<template>
  <v-card elevation="0">
    <div>
      <template v-if="payload.showLapInfo">
        <span class="font-weight-black body-2">Center</span> <span> {{ center[0] }} </span><br>
        <span class="font-weight-black body-2">Bounds :  </span><span> {{ bounds }} </span><br>
        <span class="font-weight-black body-2">Marker Location :  </span><span> Lat : {{locationObj.markers[0].position.lat}} &&  Lng : {{locationObj.markers[0].position.lng}} </span><br>
      </template>
      <v-layout>
        <span class="font-weight-bold">Lattitude: {{locationObj.markers[0].position.lat}} && Longitude :  {{locationObj.markers[0].position.lng}}</span>
        <v-spacer></v-spacer>
        <v-btn x-small color="success" @click="fitPolyline"> Fit map </v-btn><br><br>
      </v-layout>
      <v-layout v-if="payload.showConfiguration">
        <v-flex class="mx-2">
          Contol Layers position: 
          <v-select outlined dense hide-details v-model="layersPosition" :items="Positions"> </v-select>
        </v-flex>
        <v-flex>
          Attribution position:
          <v-select outlined dense hide-details v-model="attributionPosition" :items="Positions"> </v-select>
        </v-flex>
      </v-layout>
      <v-layout class="mt-4" v-if="payload.showConfiguration">
        <v-flex class="mx-2">
           Zoom: level
          <v-slider class="pt-6" hint="Im a hint" max="18" min="0" v-model="zoom"  thumb-label="always"></v-slider>
        </v-flex>
        <v-flex>
          position:
          <v-select outlined dense hide-details v-model="zoomPosition" :items="Positions"> </v-select>
        </v-flex>
      </v-layout>
      <template v-if="payload.allowMultipleMarker">
        <v-divider></v-divider>
        <h3>List of Markers</h3>
        <v-btn x-small color="primary" @click="addMarker"> Add a marker </v-btn>
        <v-simple-table dense class="">
          <tr>
            <th>Marker</th>
            <th>Latitude</th>
            <th>Longitude</th>
            <th>Tooltip</th>
            <th>Is Draggable ?</th>
            <th>Is Visible ?</th>
            <th>Remove</th>
          </tr>
          <tr v-for="(item, index) in locationObj.markers" :key="index">
            <td>{{ 'Marker ' + (index + 1) }}</td>
            <td>
              <v-text-field v-model.number="item.position.lat" type="number" dense hide-details outlined></v-text-field>
            </td>
            <td>
              <v-text-field v-model.number="item.position.lng" type="number" dense hide-details outlined></v-text-field>
            </td>
            <td>
              <v-text-field v-model="item.tooltip" dense hide-details outlined></v-text-field>
            </td>
            <td style="text-align: center">
              <v-switch v-model="item.draggable"></v-switch>
            </td>
            <td style="text-align: center">
              <v-switch v-model="item.visible"></v-switch>
            </td>
            <td style="text-align: center">
              <v-btn icon color="error" value="X" @click="removeMarker(index)"><v-icon color="error">mdi-delete</v-icon></v-btn>
            </td>
          </tr>
        </v-simple-table>
      </template>
      <template v-if="payload.provideLayerConfg">
        <v-divider></v-divider>
        <v-simple-table>
          <tr>
            <th>Layer</th>
            <th>Is Visible ?</th>
            <th>Are Markers visible ?</th>
            <th>Is Polyline visible ?</th>
          </tr>
          <tr v-for="(item, index) in stuff" :key="index">
            <td>{{ 'Layer ' + (index + 1) }}</td>
            <td>
              <v-switch v-model="item.visible"></v-switch>
            </td>
            <td>
              <v-switch v-model="item.markersVisible"></v-switch>
            </td>
            <td>
              <v-switch v-model="item.polyline.visible"></v-switch>
            </td>
          </tr>
        </v-simple-table>
      </template>
    </div>
    <l-map :zoom.sync="zoom" :options="mapOptions" :center="center" :bounds="bounds"
      :min-zoom="minZoom" :max-zoom="maxZoom" style="height: 500px; width: 100%">
      
      <l-control-layers :position="layersPosition" :collapsed="false" :sort-layers="true"/>
      
      <l-tile-layer v-for="tileProvider in tileProviders" :key="tileProvider.name"
        :name="tileProvider.name" :visible="tileProvider.visible" :url="tileProvider.url"
        :attribution="tileProvider.attribution" :token="leaflettoken" layer-type="base"/>
      
      <l-control-zoom :position="zoomPosition" />
      
      <!-- <l-control-attribution :position="attributionPosition" :prefix="attributionPrefix"/> -->
      
      <l-control-scale :imperial="imperial" />
      
      <l-marker v-for="marker in locationObj.markers" :key="marker.id" :visible="marker.visible"
        :draggable="marker.draggable" :lat-lng.sync="marker.position" :icon="marker.icon"
        @click="alert(marker)">
          <l-popup :content="marker.tooltip" />
          <l-tooltip :content="marker.tooltip" />
      </l-marker>

      <l-layer-group layer-type="overlay" name="Layer polyline">
        <l-polyline v-for="item in polylines" :key="item.id" :lat-lngs="item.points"
          :visible="item.visible" @click="alert(item)" />
      </l-layer-group>

      <!-- <l-layer-group v-for="item in stuff" :key="item.id" :visible.sync="item.visible"
        layer-type="overlay" name="Layer 1">
        <l-layer-group :visible="item.markersVisible">
          <l-marker v-for="marker in item.markers" :key="marker.id" :visible="marker.visible"
            :draggable="marker.draggable" :lat-lng="marker.position" @click="alert(marker)"/>
        </l-layer-group>
        <l-polyline :lat-lngs="item.polyline.points" :visible="item.polyline.visible" @click="alert(item.polyline)"/>
      </l-layer-group> -->
    </l-map>
  </v-card>
</template>

<script>
import { latLngBounds } from 'leaflet';
import {
  LMap,
  LTileLayer,
  LMarker,
  LPolyline,
  LLayerGroup,
  LTooltip,
  LPopup,
  LControlZoom,
  // LControlAttribution,
  LControlScale,
  LControlLayers
} from 'vue2-leaflet'

const poly1 = [
  { lng: -1.219482, lat: 47.41322 },
  { lng: -1.571045, lat: 47.457809 },
  { lng: -1.560059, lat: 47.739323 },
  { lng: -0.922852, lat: 47.886881 },
  { lng: -0.769043, lat: 48.231991 },
  { lng: 0.395508, lat: 48.268569 },
  { lng: 0.604248, lat: 48.026672 },
  { lng: 1.2854, lat: 47.982568 },
  { lng: 1.318359, lat: 47.894248 },
  { lng: 1.373291, lat: 47.879513 },
  { lng: 1.384277, lat: 47.798397 },
  { lng: 1.329346, lat: 47.754098 },
  { lng: 1.329346, lat: 47.680183 },
  { lng: 0.999756, lat: 47.635784 },
  { lng: 0.86792, lat: 47.820532 },
  { lng: 0.571289, lat: 47.820532 },
  { lng: 0.439453, lat: 47.717154 },
  { lng: 0.439453, lat: 47.61357 },
  { lng: -0.571289, lat: 47.487513 },
  { lng: -0.615234, lat: 47.680183 },
  { lng: -0.812988, lat: 47.724545 },
  { lng: -1.054688, lat: 47.680183 },
  { lng: -1.219482, lat: 47.41322 },
]

const tileProviders = [
  {
    name: 'OpenStreetMap',
    visible: true,
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
  {
    name: 'OpenTopoMap',
    visible: false,
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution:
      'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
  },
]

export default {
  name: 'Example',
  props: ['payload', 'locationObj'],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LLayerGroup,
    LTooltip,
    LPopup,
    LControlZoom,
    // LControlAttribution,
    LControlScale,
    LControlLayers,
  },
  data() {
    return {
      center: [51.505, -0.09],
      opacity: 0.6,
      leaflettoken: 'if using mapbox',
      mapOptions: {
        zoomControl: false,
        attributionControl: false,
        zoomSnap: true,
      },
      zoom: 10,
      minZoom: 1,
      maxZoom: 17,
      zoomPosition: 'topleft',
      attributionPosition: 'bottomright',
      layersPosition: 'topright',
      attributionPrefix: 'Vue2Leaflet',
      imperial: false,
      Positions: ['topleft', 'topright', 'bottomleft', 'bottomright'],
      tileProviders: tileProviders,
      polylines: [
        {
          id: 'p1',
          points: [
            { lat: 37.772, lng: -122.214 },
            { lat: 21.291, lng: -157.821 },
            { lat: -18.142, lng: -181.569 },
            { lat: -27.467, lng: -206.973 },
          ],
          visible: true,
        },
        {
          id: 'p2',
          points: [
            [-73.91, 40.78],
            [-87.62, 41.83],
            [-96.72, 32.76],
          ],
          visible: true,
        },
      ],
      stuff: [
        {
          id: 's1',
          markers: this.locationObj.markers || [{ id: 'm1', position: { lat: 51.47, lng: -0.09 }, tooltip: 'tooltip for marker1', draggable: true, visible: true }], // markers1,
          polyline: { points: poly1, visible: true },
          visible: true,
          markersVisible: true,
        },
      ],
      bounds: latLngBounds(
        { lat: 51.476483373501964, lng: -0.14668464136775586 },
        { lat: 51.52948330894063, lng: -0.019140238291583955 }
      ),
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.fitPolyline()
    })
  },
  methods: {
    alert(item) {
      console.log('this is ' + JSON.stringify(item));
    },
    addMarker: function() {
      const newMarker = {
        position: { lat: 50.5505, lng: -0.09 }, draggable: true, visible: true,
      }
      this.locationObj.markers.push(newMarker)
    },
    removeMarker (index) {
      this.locationObj.markers.splice(index, 1)
    },
    fitPolyline () {
      const bounds = latLngBounds(this.locationObj.markers.map(o => o.position)) // markers1
      this.bounds = bounds
    }
  },
};
</script>
